import { Container } from "@/components/shared/Container";

function Careers() {
  return (
    <Container>
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-center mb-8">
          انضموا إلى فريقنا في Moda Mix Trends
        </h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">لماذا العمل معنا؟</h2>
          <p className="text-lg leading-relaxed">
            في <span className="font-bold">Moda Mix Trends</span>، نحن نؤمن
            بأهمية بناء فريق عمل قوي ومبدع. نبحث دائمًا عن الأشخاص المتحمسين
            والمستعدين للمساهمة في نجاحنا المتواصل. إذا كنت مهتمًا بالعمل في
            مجال الأزياء والتسويق، أو في إدارة المستودعات، أو في وسائل التواصل
            الاجتماعي والإعلام، فإننا نرحب بانضمامك إلينا.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">الفرص المتاحة</h2>
          <ul className="list-disc pl-5 space-y-4 text-lg leading-relaxed">
            <li>
              <span className="font-bold">مسوقين بالعمولة:</span> نبحث عن مسوقين
              متحمسين للعمل بالعمولة، حيث يحصلون على عمولة عن كل عملية بيع.
            </li>
            <li>
              <span className="font-bold">إدارة المستودعات:</span> إذا كنت
              مهتمًا بالعمل في تنظيم وإدارة المستودعات، فنحن نبحث عن أفراد
              للانضمام إلى فريقنا.
            </li>
            <li>
              <span className="font-bold">إدارة وسائل التواصل الاجتماعي:</span>{" "}
              نحتاج إلى أفراد مبدعين لإدارة حساباتنا على وسائل التواصل الاجتماعي
              وابتكار محتوى جذاب.
            </li>
            <li>
              <span className="font-bold">
                شراء وسائل الإعلام (Media Buyer):
              </span>{" "}
              إذا كنت متخصصًا في شراء وسائل الإعلام والإعلانات، فإننا نرغب في
              العمل معك.
            </li>
            <li>
              <span className="font-bold">إدارة تأكيدات الطلبات:</span> نبحث عن
              أفراد لتنفيذ وإدارة تأكيدات الطلبات لضمان تجربة سلسة للعملاء.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">كيف تتقدم للعمل؟</h2>
          <p className="text-lg leading-relaxed">
            إذا كنت مهتمًا بأي من هذه الوظائف وترغب في الانضمام إلى فريقنا، يرجى
            إرسال سيرتك الذاتية ورسالة تقديمية إلى البريد الإلكتروني:
            <a
              href="mailto:contact@modamixtrends.com"
              className="text-blue-600 font-bold"
            >
              {" "}
              contact@modamixtrends.com
            </a>
            . أو الاتصال بنا عبر الهاتف على الرقم:
            <a href="tel:+212770916036" className="text-blue-600 font-bold">
              {" "}
              0770916036
            </a>
            .
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">موقعنا</h2>
          <p className="text-lg leading-relaxed">
            نعمل من مقرنا في المغرب، ونرحب بأي استفسارات أو طلبات من جميع أنحاء
            البلاد.
          </p>
        </section>
      </div>
    </Container>
  );
}

export default Careers;
