import { Link } from "react-router-dom";
import { Container } from "../shared/Container";
// import { categories } from "../../data/categories.json";

export default function Categories() {
  const [categories, setcategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchcategories = async () => {
      try {
        const response = await axios.get(`${API}/requests/categories/read.php`);
        setcategories(response.data.records);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchcategories();
  }, []);
  return (
    <Container className="bg-gray-50">
      <div className="flex flex-col items-center justify-center mb-8 md:mb-12">
        <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-2">
          استكشف تصنيفاتنا
        </h2>
        <p className="text-gray-600 text-md md:text-xl max-w-md text-center">
          اعثر على المنتجات المثالية لاحتياجاتك في مجموعتنا الواسعة من
          التصنيفات.
        </p>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-8">
        {isLoading ? (
          <>
            <Skeleton className="w-[200px] h-[200px] rounded-md" />
            <Skeleton className="w-[200px] h-[200px] rounded-md" />
            <Skeleton className="w-[200px] h-[200px] rounded-md" />
            <Skeleton className="w-[200px] h-[200px] rounded-md" />
          </>
        ) : (
          categories.map((category, index) => (
            <Category
              key={index} // Ensure each Category has a unique key
              link={category.link}
              name={category.title}
              imageSrc={`${API}/uploads/${category.thumbnail}`}
              description={category.subtitle}
            />
          ))
        )}
      </div>
    </Container>
  );
}

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { API } from "@/config";
import axios from "axios";
import { Skeleton } from "../ui/skeleton";
import { MoveLeft } from "lucide-react";
export const Category = ({ link, name, imageSrc, description }) => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden group relative">
      <Link to={`category/${link}`} className="block">
        <div className="relative">
          <img
            alt="Electronics"
            className="w-full h-auto object-cover group-hover:scale-110 group-hover:-translate-y-2 transition-all duration-300"
            height="200"
            src={imageSrc}
            style={{
              aspectRatio: "300/200",
              objectFit: "cover",
            }}
            width="300"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-gray-900/80 to-transparent flex items-end p-4">
            <h3 className="text-white text-lg font-semibold">{name}</h3>
          </div>
          <div className="absolute inset-0 bg-gray-900/0 group-hover:bg-gray-900/50 transition-colors duration-300 flex items-center justify-center">
            <div className="text-white text-lg font-semibold opacity-0 group-hover:opacity-100 transition-opacity duration-300 space-y-2 p-4">
              <p className="text-sm font-normal">{description}</p>
              <span className="flex items-center gap-2 text-sm absolute bottom-4 left-4">
                تصفح
                <MoveLeft className="h-4 w-4" />
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

Category.propTypes = {
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
