import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { ShoppingCart } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "../ui/button";
import React from "react";
import { PropTypes } from "prop-types";
import { FaHouse } from "react-icons/fa6";

export default function TopBar(props) {
  const { pathArr, label, addNewLink } = props;
  return (
    <div className="flex justify-between items-center my-4 bg-white">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to="/">
              <FaHouse className="w-4 h-4" />
            </Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          {pathArr.map((path, index) => {
            if (index < pathArr.length - 1) {
              return (
                <React.Fragment key={index}>
                  <BreadcrumbItem>
                    <Link to={`/${path}`}>{path}</Link>
                  </BreadcrumbItem>
                  <BreadcrumbSeparator />
                </React.Fragment>
              );
            } else {
              return (
                <BreadcrumbItem className="font-bold text-black" key={index}>
                  {path}
                </BreadcrumbItem>
              );
            }
          })}
        </BreadcrumbList>
      </Breadcrumb>
      {addNewLink && (
        <Button
          className="bg-[#1814F3] hover:bg-[#4c14f3] rounded-full"
          asChild
        >
          <Link to={addNewLink}>
            <ShoppingCart className="mr-2 h-4 w-4" /> Add New {label}
          </Link>
        </Button>
      )}
    </div>
  );
}

TopBar.propTypes = {
  pathArr: PropTypes.array.isRequired,
  label: PropTypes.string,
  addNewLink: PropTypes.string,
};
