import { Button } from "@/components/ui/button";
import { CircleCheckIcon, MoveLeft, PackageIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCart } from "@/context/CartContext";
import { Container } from "@/components/shared/Container";
import axios from "axios";
import { API } from "@/config";

export default function OrderSubmit() {
  const [trackingNumber, setTrackingNumber] = useState("");
  const [order, setOrder] = useState({});
  const { clearCart } = useCart();

  useEffect(() => {
    clearCart();
    const tracking = localStorage.getItem("trackingNumber");
    setTrackingNumber(tracking);
    const order = JSON.parse(localStorage.getItem("order"));
    setOrder(order);

    // Function to track purchase events
    const trackPurchase = async () => {
      try {
        // Fetch pixel data
        const response = await axios.get(
          `${API}/requests/settings/pixels/read.php`
        );
        if (response.data.success) {
          const {
            facebook_pixel,
            snapchat_pixel,
            tiktok_pixel,
            google_analytics_id,
          } = response.data.pixels;

          // Ensure pixel data is available and tracking functions are loaded
          if (facebook_pixel) {
            window.fbq &&
              window.fbq("track", "Purchase", {
                value: order.total_price, // Replace with actual purchase value
                currency: "MAD",
                content_ids: [tracking],
              });
          }
          if (snapchat_pixel) {
            window.snapi &&
              window.snapi("track", "Purchase", {
                value: order.total_price, // Replace with actual purchase value
                currency: "MAD",
                transaction_id: tracking,
              });
          }
          if (tiktok_pixel) {
            window.ttq &&
              window.ttq("track", "Purchase", {
                value: order.total_price, // Replace with actual purchase value
                currency: "MAD",
                content_id: tracking,
              });
          }
          if (google_analytics_id) {
            window.gtag &&
              window.gtag("event", "purchase", {
                transaction_id: tracking,
                affiliation: "Online Store",
                value: order.total_price, // Replace with actual purchase value
                currency: "MAD",
              });
          }
        }
      } catch (error) {
        console.error("Error tracking purchase:", error);
      }
    };

    trackPurchase();
  }, []);

  return (
    <Container className="bg-gray-100">
      <section className="w-full h-full flex items-center justify-center">
        <div className="max-w-2xl w-full bg-white dark:bg-gray-900 rounded-xl shadow-xl p-8 sm:p-12 my-8">
          <div className="flex flex-col items-center">
            <div className="bg-[#4ade80] rounded-full p-4 mb-6">
              <CircleCheckIcon className="text-white w-12 h-12" />
            </div>
            <h1 className="text-3xl font-bold mb-4 text-gray-900 dark:text-gray-100 text-center">
              تم وضع الطلب بنجاح
            </h1>
            <p className="text-gray-600 dark:text-gray-400 mb-10 text-center">
              شكرًا لك على عملية الشراء
            </p>
            <div className="bg-gray-100 dark:bg-gray-800 rounded-lg p-6 mb-10 w-full">
              <div className="flex flex-col md:flex-row items-center justify-between">
                <div className="flex gap-4 items-center mb-4 md:mb-0">
                  <PackageIcon className="text-gray-500 dark:text-gray-400 mr-3 h-6 w-6" />
                  <p className="text-gray-600 dark:text-gray-400 font-medium">
                    رقم تتبع الطلب:{" "}
                    <span className="font-bold text-green-500">
                      {trackingNumber}
                    </span>
                  </p>
                </div>
                <Button
                  asChild
                  className="text-[#4ade80] flex gap-4 dark:text-[#4ade80] hover:bg-[#4ade80] hover:text-white dark:hover:bg-[#4ade80] dark:hover:text-gray-950"
                  size="sm"
                  variant="outline"
                >
                  <Link to={`/tracking-orders/${trackingNumber}`}>
                    <PackageIcon className="mr-2 h-4 w-4" />
                    <span>تتبع طلبك</span>
                  </Link>
                </Button>
              </div>
            </div>
            <div className="bg-gray-100 dark:bg-gray-800 rounded-lg p-6 mb-10 w-full">
              <h2 className="text-lg font-bold mb-4 text-gray-900 dark:text-gray-100">
                تفاصيل الطلب
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <p className="text-gray-600 dark:text-gray-400 font-medium">
                    رقم الطلب:
                  </p>
                  <span className="font-bold text-green-500">
                    {trackingNumber}
                  </span>
                </div>
                <div>
                  <p className="text-gray-600 dark:text-gray-400 font-medium">
                    تاريخ الطلب:
                  </p>
                  <p className="text-gray-900 dark:text-gray-100 font-medium">
                    {new Date().toLocaleDateString()}
                  </p>
                </div>
                <div>
                  <p className="text-gray-600 dark:text-gray-400 font-medium">
                    المبلغ الإجمالي:
                  </p>
                  <p className="text-gray-900 dark:text-gray-100 font-medium">
                    دم {order.total_price + order.delivery_price}
                  </p>
                </div>
                <div>
                  <p className="text-gray-600 dark:text-gray-400 font-medium">
                    طريقة الدفع:
                  </p>
                  <p className="text-gray-900 dark:text-gray-100 font-medium">
                    الدفع عند الاستلام
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row items-center justify-between w-full mb-8">
              <Link
                to="/"
                className="inline-flex gap-4 items-center rounded-md border border-[#4ade80] bg-transparent px-4 py-2 text-sm font-medium text-[#4ade80] shadow-sm transition-colors hover:bg-[#4ade80] hover:text-gray-950 focus:outline-none focus:ring-2 focus:ring-[#4ade80] focus:ring-offset-2 dark:border-[#4ade80] dark:text-[#4ade80] dark:hover:bg-[#4ade80] dark:hover:text-gray-950 dark:focus:ring-[#4ade80]"
              >
                <span>متابعة التسوق</span>
                <MoveLeft className="h-4 w-4" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
}
