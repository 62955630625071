import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { API } from "@/config";
import { ShoppingCart, Trash2 } from "lucide-react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import { useCart } from "@/context/CartContext";

export function CartSheet() {
  const {
    cartItems,
    removeItemFromCart,
    totalPrice,
    isCartSheetOpen,
    openCartSheet,
    closeCartSheet,
  } = useCart();

  const removeItem = (indexToRemove) => {
    removeItemFromCart(indexToRemove);
  };

  return (
    <Sheet
      className="relative"
      open={isCartSheetOpen}
      onOpenChange={closeCartSheet}
    >
      <div
        onClick={openCartSheet}
        className="hover:text-gray-600 transition-colors relative cursor-pointer"
      >
        <ShoppingCart className="h-6 w-6" />
        {cartItems.length > 0 && (
          <div className="absolute -bottom-3 -right-3 bg-red-500 text-white rounded-full px-2 py-0.5 text-xs font-medium">
            {cartItems.length}
          </div>
        )}
      </div>
      <SheetContent>
        <SheetHeader className="border-b-2 pb-4">
          <SheetTitle>
            <div className="flex items-center gap-4">
              <h3 className="text-lg font-semibold">سلة التسوق</h3>
              <span className="text-gray-500 ml-8">
                ({cartItems.length} منتجات)
              </span>
            </div>
          </SheetTitle>
          <SheetDescription className="text-start">
            المنتجات التي قمت بإضافتها لسلة التسوق
          </SheetDescription>
        </SheetHeader>

        <div className="space-y-4 mt-8">
          {cartItems.length > 0 ? (
            cartItems.map((item, index) => (
              <div key={index} className="space-y-4">
                <CartItem
                  imageSrc={`${API}/uploads/${item.thumbnail_url}`}
                  name={item.title}
                  quantity={parseInt(item.quantity)}
                  price={parseFloat(item.price)}
                  onRemove={() => removeItem(index)}
                  selectedVariants={item.selectedVariants}
                />
                {index < cartItems.length - 1 && <hr />}
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center">
              <p className="text-gray-500">
                لا توجد عناصر في سلة التسوق الخاصة بك
              </p>
            </div>
          )}
        </div>

        <div className="block bg-gray-200 p-4 absolute bottom-0 w-full right-0">
          <div className="mt-4 flex items-center justify-between">
            <span className="text-gray-500">السعر الإجمالي:</span>
            <span className="font-semibold">{`دم${totalPrice.toFixed(
              2
            )}`}</span>
          </div>
          <br />
          {cartItems.length > 0 ? (
            <SheetClose asChild className="mb-4">
              <Button
                asChild
                type="submit"
                className="w-full bg-green-900 text-white  hover:bg-primary"
              >
                <Link to="/cart">اتمم عملية الشراء</Link>
              </Button>
            </SheetClose>
          ) : null}

          <SheetClose asChild>
            <Button
              type="cancel"
              className="w-full bg-transparent border-2 border-primary text-primary"
            >
              إغلاق
            </Button>
          </SheetClose>
        </div>
      </SheetContent>
    </Sheet>
  );
}

export const CartItem = ({
  imageSrc,
  name,
  selectedVariants,
  quantity,
  price,
  onRemove,
}) => {
  const variantsDisplay = Object.entries(selectedVariants)
    .map(([key, value]) => `${key}: ${value}`)
    .join(", ");
  return (
    <div className="flex items-center gap-4">
      <img
        alt="Product"
        className="rounded-md"
        src={imageSrc}
        style={{
          aspectRatio: "60/60",
          objectFit: "cover",
        }}
        width="60"
        height="60"
      />
      <div className="w-full">
        <h4 className="font-semibold">{name}</h4>
        <p className="text-gray-500 text-sm">{variantsDisplay}</p>
        <p className="text-gray-500 text-sm">الكمية: {quantity}</p>
      </div>
      <div>
        <p className="font-semibold">{`دم${price}`}</p>
        <Button
          className="ml-auto p-2 hover:bg-red-600"
          size="icon"
          variant="ghost"
          onClick={onRemove}
        >
          <Trash2 className="size-6 text-gray-500" />
        </Button>
      </div>
    </div>
  );
};

CartItem.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selectedVariants: PropTypes.object,
  quantity: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  onRemove: PropTypes.func,
};
