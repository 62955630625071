import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API } from "@/config";
import Products from "@/components/sections/Products";
import PropTypes from "prop-types";
import notfound from "@/assets/box.svg";
import { Button } from "@/components/ui/button";
import { HomeIcon } from "lucide-react";
import TopBar from "@/components/shared/TopBar";
import { Loading } from "@/components/shared/Loading";
import { Container } from "@/components/shared/Container";

const CategoryPage = () => {
  const { link } = useParams();
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  console.log(link);

  useEffect(() => {
    const fetchCategoryAndProducts = async () => {
      setIsLoading(true);
      try {
        const categoryResponse = await fetch(
          `${API}/requests/categories/getCategoryByLink.php?link=${link}`
        );
        if (!categoryResponse.ok) throw new Error("Failed to fetch category");
        const categoryData = await categoryResponse.json();
        if (!categoryData || !categoryData.category)
          throw new Error("Category data is invalid");

        setCategory(categoryData.category);

        const productsResponse = await fetch(
          `${API}/requests/products/getProductsByCategory.php?category_id=${categoryData.category.id}`
        );
        if (!productsResponse.ok) throw new Error("Failed to fetch products");
        const productsData = await productsResponse.json();

        if (productsData && productsData.products) {
          setProducts(productsData.products);
        } else {
          setProducts([]);
        }
      } catch (error) {
        console.error("Error fetching category or products:", error);
        // Optionally, you can set error states here if you have them, to show error messages in the UI
        setProducts([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategoryAndProducts();
  }, [link]);

  console.log(category, products);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Container className="md:pt-0">
        <TopBar pathArr={[`${link}`]} />
        <div className="bg-white ">
          <CategoryInfo
            thumbnail={`${API}/uploads/${category.thumbnail}`}
            title={category.title}
            subtitle={category.subtitle}
          />
        </div>
      </Container>
      {
        //check first if ther is a products
        products.length > 0 ? (
          <Products isLoading={isLoading} products={products} />
        ) : (
          <div className="flex justify-center text-center pb-12">
            <div className="continer max-w-60">
              <img src={notfound} />
              <div className="content">
                <h1 className="text-2xl text-center font-extrabold mb-4">
                  لم يتم العثور على منتجات
                </h1>
                <p className="text-sm text-gray-600">
                  نأسف، ولكن لا توجد منتجات متاحة في هذه الفئة في الوقت الحالي.{" "}
                  <br /> يرجى التحقق لاحقًا أو استكشاف فئاتنا الأخرى.
                </p>
                <div className="mt-6 flex justify-center gap-2 sm:flex-row">
                  <Button
                    asChild
                    className="inline-flex gap-4 items-center rounded-full bg-[#003827] px-4 py-2 text-sm font-medium text-primary-foreground shadow-sm transition-colors hover:bg-[#003827]/90 focus:outline-none focus:ring-2 focus:ring-[#003827] focus:ring-offset-2"
                    prefetch={false}
                  >
                    <Link to="/" className="text-white">
                      <HomeIcon className="mr-2 h-4 w-4" />
                      الانتقال إلى الصفحة الرئيسية
                    </Link>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default CategoryPage;

const CategoryInfo = ({ thumbnail, title, subtitle }) => {
  return (
    <div className="flex justify-start items-center p-4 gap-4 relative">
      <div className="size-32 min-w-32 overflow-hidden rounded-full grayscale brightness-150">
        <img
          src={thumbnail}
          alt={title}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="flex flex-col absolute right-24">
        <h2 className="text-xl md:text-5xl font-extrabold mb-4 min-w-64">
          {title}
        </h2>
        <p className="text-gray-600 text-sm md:text-lg ml-8 min-w-40">
          {subtitle}
        </p>
      </div>
    </div>
  );
};

CategoryInfo.propTypes = {
  thumbnail: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};
