import {
  CardTitle,
  CardDescription,
  CardHeader,
  CardContent,
  Card,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { RadioGroupItem, RadioGroup } from "@/components/ui/radio-group";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { FaHandHoldingDollar, FaTruck } from "react-icons/fa6";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { API } from "@/config";
import { PropTypes } from "prop-types";
import { Loading } from "@/components/shared/Loading";
import TopBar from "@/components/shared/TopBar";
import { useCart } from "@/context/CartContext"; // Import useCart
import { Container } from "@/components/shared/Container";

export default function ProductPage() {
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({
    title: "",
    subtitle: "",
    description: "",
    categories: [],
    type: "",
    thumbnail_url: "",
    images: [],
    price: 0,
    comparing_price: 0,
    stock: 0,
    has_options: false,
    options: [],
    variant_combinations: [],
  });
  const [selectedVariants, setSelectedVariants] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [dynamicPrice, setDynamicPrice] = useState(product.price);
  const [isOutOfStock, setIsOutOfStock] = useState(false);

  useEffect(() => {
    const fetchProduct = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${API}/requests/products/read_single.php?id=${id}`
        );
        const productData = response.data;
        setProduct(productData);

        const thumbnail = productData.thumbnail_url;
        thumbnail && setThumbnailPreview(thumbnail);
        // Initialize selectedVariants with the first variant options by default
        const initialVariants = {};
        if (productData.has_options) {
          productData.options.forEach((option) => {
            initialVariants[option.title] = option.variants[0];
          });
          //if the product has options
          updateDynamicPrice(initialVariants, productData.variant_combinations);
        } else {
          setDynamicPrice(productData.price);
        }

        setSelectedVariants(initialVariants);
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const updateDynamicPrice = (selectedVariants, variantCombinations) => {
    const combination = variantCombinations.find((combination) =>
      combination.combination.every(
        (c) => selectedVariants[c.title] === c.variant
      )
    );
    if (combination) {
      setDynamicPrice(combination.price);
      setIsOutOfStock(combination.stock == 0);
    } else {
      setDynamicPrice(product.price); // Default price if no combination found
      setIsOutOfStock(false);
    }
  };

  console.log("Product:", product);
  console.log("isOutOfStock:", isOutOfStock);

  const handleVariantChange = (optionTitle, variant) => {
    const newSelectedVariants = {
      ...selectedVariants,
      [optionTitle]: variant,
    };

    setSelectedVariants(newSelectedVariants);

    // Update price dynamically
    updateDynamicPrice(newSelectedVariants, product.variant_combinations);
  };

  const { addItemToCart } = useCart(); // Destructure addItemToCart

  const addToCart = () => {
    const item = {
      product_id: product.id,
      selectedVariants,
      quantity,
      price: dynamicPrice,
      title: product.title,
      thumbnail_url: product.thumbnail_url,
    };
    addItemToCart(item); // Use addItemToCart from context
    console.log("Item added to cart", item);
  };

  const buyNow = () => {
    addToCart();
    navigate("/cart");
  };

  const [thumbnailPreview, setThumbnailPreview] = useState();

  const handleThumbnailChange = (src) => {
    setThumbnailPreview(src);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <TopBar pathArr={[product.title]} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        <div className="relative">
          <div className="ring-2 ring-gray-100 rounded-xl overflow-hidden">
            <img
              alt={product.title}
              width="100%"
              height="100%"
              src={
                thumbnailPreview
                  ? `${API}/uploads/${thumbnailPreview}`
                  : "/noproducts.svg"
              }
              style={{ objectFit: "cover" }}
            />
          </div>
          <div className="mt-4 flex flex-wrap gap-4 justify-evenly">
            {product.images.map((image, index) => (
              <OtherImage
                key={index}
                src={image}
                onchange={() => handleThumbnailChange(image)}
                isActive={image === thumbnailPreview}
              />
            ))}
          </div>
        </div>
        <div>
          <Card className="w-full">
            <CardHeader>
              <CardTitle>{product.title}</CardTitle>
              <CardDescription>{product.subtitle}</CardDescription>
            </CardHeader>
            <CardContent>
              {product.has_options &&
                product.options.map((option, index) => (
                  <OptionWithVariants
                    key={index}
                    option={option}
                    handleVariantChange={handleVariantChange}
                    selectedVariant={selectedVariants[option.title]}
                  />
                ))}
              <div className="mt-4 flex items-center justify-between">
                <span className="text-2xl font-bold">دم {dynamicPrice}</span>
                <span className="text-xl text-gray-500 line-through">
                  دم {product.comparing_price}
                </span>
              </div>
              <div className="mt-4">
                <Label htmlFor="quantity">الكمية</Label>
                <div className="flex items-center gap-4">
                  <Button
                    variant="outline"
                    onClick={() => setQuantity((q) => Math.max(1, q - 1))}
                  >
                    -
                  </Button>
                  <Input
                    className="w-12 text-center px-0"
                    id="quantity"
                    readOnly
                    type="number"
                    value={quantity}
                    min="1"
                  />
                  <Button
                    variant="outline"
                    onClick={() => setQuantity((q) => q + 1)}
                  >
                    +
                  </Button>
                </div>
              </div>
              {/* <div className="mt-4">
                <Label>Total</Label>
                <div className="font-bold text-lg">
                  {quantity} x dh {dynamicPrice} = dh {quantity * dynamicPrice}
                </div>
              </div> */}

              <div className="mt-6 flex justify-center items-center flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 fixed bottom-safe-bottom right-0 left-0 p-2 md:relative bg-white w-full">
                {isOutOfStock ? (
                  <div className="mt-4 text-sm text-red-500 font-bold">
                    هذا الخيار من المنتج غير متوفر في المخزون. الرجاء اختيار
                    خيار آخر.
                  </div>
                ) : (
                  <Button
                    className="bg-gradient-to-r w-9/12 px-12 from-green-400 to-green-600 text-white animate__animated animate__rubberBand animate__infinite"
                    onClick={buyNow}
                  >
                    اشتري الآن
                  </Button>
                )}

                {/* <Button variant="outline" onClick={addToCart}>
                  أضف إلى السلة
                </Button> */}
              </div>

              <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="flex items-center gap-2">
                  <FaTruck className="w-6 h-6 text-green-500" />
                  <div>
                    <div className="font-medium">توصيل مجاني</div>
                    <div className="text-sm text-gray-500">
                      يتم التوصيل خلال 24 ساعة!
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <FaHandHoldingDollar className="w-6 h-6 text-green-500" />
                  <div>
                    <div className="font-medium">استرجاع و الاستبدال</div>
                    <div className="text-sm text-gray-500">
                      استرجاع و الاستبدال خلال 48 ساعة!
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      <div className="mt-12">
        <Card>
          <CardHeader>
            <CardTitle>الوصف</CardTitle>
          </CardHeader>
          <CardContent>
            <div dangerouslySetInnerHTML={{ __html: product.description }} />
          </CardContent>
        </Card>
      </div>
    </Container>
  );
}

const OtherImage = ({ src, onchange, isActive }) => {
  console.log("isActive", isActive);
  return (
    <div
      className={`border-2 rounded-md overflow-hidden cursor-pointer size-16 md:size-20 ${
        isActive ? "border-primary " : "border-white"
      }`}
      onClick={onchange}
    >
      <img
        alt="Additional Image"
        src={`${API}/uploads/${src}`}
        className="w-full h-full"
        style={{
          aspectRatio: `1/1`,
          objectFit: "cover",
        }}
      />
    </div>
  );
};
OtherImage.propTypes = {
  src: PropTypes.string.isRequired,
  onchange: PropTypes.func,
  isActive: PropTypes.bool,
};

const OptionWithVariants = ({
  option,
  handleVariantChange,
  selectedVariant,
}) => {
  const { title, variants } = option;
  return (
    <div className="my-4">
      <h1>{title}</h1>
      <RadioGroup
        defaultValue={selectedVariant || variants[0]}
        id={title}
        onValueChange={(value) => handleVariantChange(title, value)}
      >
        <div className="flex flex-row-reverse flex-wrap items-center gap-4 justify-start">
          {variants.map((variant) => (
            <Label
              key={variant}
              className="border border-gray-300 rounded-md p-2 flex items-center space-x-2 cursor-pointer [&:has(:checked)]:bg-gray-100 dark:[&:has(:checked)]:bg-gray-800"
              htmlFor={`${title}-${variant}`}
            >
              <RadioGroupItem id={`${title}-${variant}`} value={variant} />
              <span>{variant}</span>
            </Label>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};
OptionWithVariants.propTypes = {
  option: PropTypes.shape({
    title: PropTypes.string.isRequired,
    variants: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  handleVariantChange: PropTypes.func.isRequired,
  selectedVariant: PropTypes.string,
};
