import { Container } from "@/components/shared/Container";

function FAQs() {
  return (
    <Container>
      <h1 className="text-3xl font-bold mb-6">الأسئلة الشائعة</h1>
      <div className="space-y-4">
        <div>
          <h2 className="text-xl font-semibold">كيف يمكنني تتبع طلبي؟</h2>
          <p>
            يمكنك تتبع طلبك عن طريق زيارة صفحة تتبع الطلبات وإدخال رقم التتبع
            الخاص بك.
          </p>
        </div>
        <div>
          <h2 className="text-xl font-semibold">ما هي سياسة الإرجاع لديكم؟</h2>
          <p>
            نقبل الإرجاع خلال 30 يومًا من تاريخ الشراء. يرجى زيارة صفحة الشحن
            والإرجاع لمزيد من التفاصيل.
          </p>
        </div>
        <div>
          <h2 className="text-xl font-semibold">
            هل تقومون بالتوصيل داخل البلاد؟
          </h2>
          <p>
            نعم، نقوم بالتوصيل في جميع المدن الوطنية. يرجى ملاحظة أننا نستخدم
            الدفع عند الاستلام كوسيلة دفع.
          </p>
        </div>
        <div>
          <h2 className="text-xl font-semibold">
            ما هي وسائل الدفع التي تقبلونها؟
          </h2>
          <p>
            نقبل الدفع عند الاستلام (Cash on Delivery) بالإضافة إلى بطاقات Visa،
            MasterCard، PayPal، وبطاقات الائتمان الرئيسية الأخرى.
          </p>
        </div>
        <div>
          <h2 className="text-xl font-semibold">متى يصل لي الطلب؟</h2>
          <p>
            تعتمد على شركة الشحن التي تم إختيارها والمسؤول عن التوصيل، نحن نوفر
            مجموعة من المسؤولين عن الشحن بمجموعة من المدن الكبرى، وعادة تأخد من
            يوم إلى 2 أيام من الطلب وتأكيده.
          </p>
        </div>
        <div>
          <h2 className="text-xl font-semibold">
            في حالة وجود عيب مصنعي في المنتج؟
          </h2>
          <p>في حالة وجود عيب مصنعي للاستبدال اتصل ب:0620161140</p>
        </div>
        <div>
          <h2 className="text-xl font-semibold">
            أريد طلب كميات كبيرة هل يوجد خصم؟
          </h2>
          <p>
            يوفر المتجر خدمة توصيل الكميات الكبيرة في حالة الطلب وهنالك خصم يصل
            من 10-20٪ على بعض المنتجات يمكن التواصل المباشر معنا عبر صفحة اتصل
            بنا 0694681426 والإستفسار عن الكميات والخصم
          </p>
        </div>
      </div>
    </Container>
  );
}

export default FAQs;
