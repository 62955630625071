import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import "./styling/animations.scss";
import { CartProvider } from "./context/CartContext.jsx";
import DisableRightClick from "./utils/DisableRightClick.jsx";

ReactDOM.createRoot(document.getElementById("root")).render(
  <CartProvider>
    <DisableRightClick>
      <App />
    </DisableRightClick>
  </CartProvider>
);
