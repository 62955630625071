import Footer from "@/components/sections/Footer";
import Header from "@/components/sections/Header";
import TopBanner from "@/components/sections/TopBanner";
import WhatsAppPopup from "@/upsales/WhatsAppPopup";
// import { API } from "@/config";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DefaultLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" }); // Smooth scrolling
    };

    scrollToTop();
  }, [navigate]);
  /*
  //sending heart beats to the server
  const API_URL = `${API}/requests/visitors/heartbeat.php`;

  function sendHeartbeat() {
    fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ timestamp: Math.floor(Date.now() / 1000) }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Heartbeat sent:", data);
      })
      .catch((error) => {
        console.error("Error sending heartbeat:", error);
      });
  }

  // Send a heartbeat every 1 seconds
  setInterval(sendHeartbeat, 5000);

  // Send an initial heartbeat when the page loads
  sendHeartbeat();*/

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition:Bounce
      />
      <TopBanner />
      <Header />
      <div style={{ minHeight: "calc(100vh - 350px)" }}>
        <Outlet />
      </div>
      <Footer />
      <WhatsAppPopup />
    </div>
  );
};

export default DefaultLayout;
