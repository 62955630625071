import { Container } from "@/components/shared/Container";

function PrivacyPolicy() {
  return (
    <Container>
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-center mb-8">سياسة الخصوصية</h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. المقدمة</h2>
          <p className="text-lg leading-relaxed">
            في <span className="font-bold">Moda Mix Trends</span>، نحن ملتزمون
            بحماية خصوصيتك. تصف هذه السياسة كيفية جمع واستخدام ومشاركة وحماية
            معلوماتك الشخصية عند استخدام موقعنا الإلكتروني.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            2. المعلومات التي نجمعها
          </h2>
          <p className="text-lg leading-relaxed">
            قد نجمع أنواعًا مختلفة من المعلومات الشخصية بما في ذلك:
          </p>
          <ul className="list-disc pl-5 space-y-4 text-lg leading-relaxed">
            <li>
              معلومات الاتصال، مثل الاسم وعنوان البريد الإلكتروني ورقم الهاتف.
            </li>
            <li>معلومات الدفع عند إجراء عملية شراء.</li>
            <li>
              معلومات الاستخدام، مثل الصفحات التي تزورها والمنتجات التي
              تستعرضها.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            3. كيفية استخدامنا للمعلومات
          </h2>
          <p className="text-lg leading-relaxed">
            نستخدم المعلومات التي نجمعها للأغراض التالية:
          </p>
          <ul className="list-disc pl-5 space-y-4 text-lg leading-relaxed">
            <li>معالجة طلبات الشراء وتقديم المنتجات والخدمات.</li>
            <li>التواصل معك بشأن طلباتك أو حسابك أو استفساراتك.</li>
            <li>تحسين تجربة المستخدم وتخصيص المحتوى والإعلانات.</li>
            <li>تحليل استخدام الموقع وتحسين خدماتنا.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. مشاركة المعلومات</h2>
          <p className="text-lg leading-relaxed">
            قد نشارك معلوماتك الشخصية مع أطراف ثالثة في الحالات التالية:
          </p>
          <ul className="list-disc pl-5 space-y-4 text-lg leading-relaxed">
            <li>
              مع مقدمي الخدمات الذين يساعدوننا في تشغيل موقعنا وتقديم خدماتنا.
            </li>
            <li>عند الضرورة للامتثال للقوانين واللوائح المعمول بها.</li>
            <li>
              إذا كنا نعتقد أن ذلك ضروري لحماية حقوقنا أو سلامتك أو سلامة
              الآخرين.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">5. حماية المعلومات</h2>
          <p className="text-lg leading-relaxed">
            نحن نتخذ تدابير أمنية مناسبة لحماية معلوماتك الشخصية من الوصول غير
            المصرح به أو الكشف عنها أو التغيير أو التدمير. ومع ذلك، لا يمكننا
            ضمان الأمان المطلق لأي معلومات تقوم بإرسالها إلينا عبر الإنترنت.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">6. حقوقك</h2>
          <p className="text-lg leading-relaxed">
            لديك الحق في الوصول إلى معلوماتك الشخصية وتصحيحها أو تحديثها أو طلب
            حذفها. إذا كنت ترغب في ممارسة هذه الحقوق، يرجى الاتصال بنا عبر
            البريد الإلكتروني:
            <a
              href="mailto:contact@modamixtrends.com"
              className="text-blue-600 font-bold"
            >
              {" "}
              contact@modamixtrends.com
            </a>
            .
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            7. ملفات تعريف الارتباط (Cookies)
          </h2>
          <p className="text-lg leading-relaxed">
            نستخدم ملفات تعريف الارتباط لتحسين تجربتك على موقعنا. يمكنك ضبط
            إعدادات متصفحك لرفض ملفات تعريف الارتباط، ولكن قد يؤثر ذلك على بعض
            وظائف الموقع.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            8. التعديلات على سياسة الخصوصية
          </h2>
          <p className="text-lg leading-relaxed">
            قد نقوم بتحديث سياسة الخصوصية من وقت لآخر. سيتم نشر أي تغييرات على
            هذه الصفحة، ويعني استخدامك المستمر للموقع بعد أي تعديل موافقتك على
            السياسة المعدلة.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">9. اتصل بنا</h2>
          <p className="text-lg leading-relaxed">
            إذا كان لديك أي أسئلة أو استفسارات حول سياسة الخصوصية هذه، يمكنك
            الاتصال بنا عبر البريد الإلكتروني:
            <a
              href="mailto:contact@modamixtrends.com"
              className="text-blue-600 font-bold"
            >
              {" "}
              contact@modamixtrends.com
            </a>
            أو الهاتف:
            <a href="tel:+212620161140" className="text-blue-600 font-bold">
              {" "}
              0620161140
            </a>
            .
          </p>
        </section>
      </div>
    </Container>
  );
}

export default PrivacyPolicy;
