import { Button } from "@/components/ui/button";

export default function Banner() {
  return (
    <section className="py-12 px-6 md:px-12">
      <div className="bg-primary container mx-auto max-w-[800px] grid grid-cols-1 md:grid-cols-2 gap-8 items-center p-4 rounded-xl">
        <div>
          <img
            alt="Product"
            className="w-full h-auto rounded-md"
            height="200"
            src="/modal.png"
            style={{
              aspectRatio: "400/200",
              objectFit: "cover",
            }}
            width="400"
          />
        </div>
        <div className="space-y-4">
          <div className="bg-red-500 px-4 py-2 rounded-md inline-block text-white font-semibold">
            عرض خاص للعملاء الجدد
          </div>
          <h2 className="text-3xl font-bold text-white">
            خصم 20% على أول عملية شراء
          </h2>
          <p className="text-gray-300">واحصل على هدية مجانية</p>
          <Button className="bg-white hover:bg-gray-200 text-[#003626] font-semibold px-6 py-3 rounded-md">
            استفد الآن
          </Button>
        </div>
      </div>
    </section>
  );
}
