import { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { CircleAlert, SquareArrowOutUpLeft } from "lucide-react";
import "@/styling/whatsapp_pluging.scss";

const WhatsAppPopup = () => {
  const [isActive, setIsActive] = useState(false);
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //get the agents from the database
  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await axios.get(
          `${API}/requests/users/read.php?type=customer_support`
        );
        setAgents(response.data.users);
      } catch (error) {
        console.error("Error fetching agents:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAgents();
  }, []);

  console.log(agents);

  const conf = {
    labels: ["تواصل معنا", "مرحبا", "تحتاج مساعدة؟"],
    titel: "بدء محادثة",
    subtitel: "أهلا! انقر فوق أحد أعضائنا أدناه للدردشة على WhatsApp",
    notification: "عادة ما يرد الفريق في غضون بضع دقائق.",
  };

  const togglePopup = () => {
    setIsActive(!isActive);
  };

  const openWtp = (number, text) => {
    window.open(`https://wa.me/${number}?text=${text}`);
  };

  return (
    <div id="whatsapp" className={`relative ${isActive ? "active" : ""}`}>
      <div className="btn-container">
        <div
          className={`icon-container main-btn bg-green-500 ${
            isActive ? "active" : ""
          }`}
          onClick={togglePopup}
        ></div>
        <div
          className={`label animate__animated ${
            isActive ? "fadeOutDown" : "fadeInUp"
          }`}
        >
          {conf.labels[1]}
        </div>
      </div>
      <div
        className={`wtp-container animate__animated ${
          isActive ? "fadeInUp" : "hidden"
        }`}
      >
        <div className="wtp-header bg-green-500 flex justify-between items-center">
          <div className="instructions text-right py-2 px-4 text-white w-60">
            <p className="h1 text-2xl md:text-3xl">{conf.titel}</p>
            <p className="sub text-xs md:text-sm">{conf.subtitel}</p>
          </div>
          <div className="flex justify-center items-center w-24">
            <FaWhatsapp className="text-white size-8" />
          </div>
        </div>
        <div className="wtp-body px-4 py-2 relative">
          <p className="header text-center text-sm text-gray-600 mb-4">
            {conf.notification}
          </p>
          {isLoading ? (
            <div className="flex justify-center items-center h-32">
              <div className="lds-dual-ring"></div>
            </div>
          ) : agents.length === 0 ? (
            <div className="flex justify-center items-center h-32">
              <p className="text-gray-500">لا يوجد وكلاء حاليا</p>
            </div>
          ) : (
            agents.map((item, index) => (
              <WtpItem key={index} item={item} openWtp={openWtp} />
            ))
          )}
        </div>
        <div className="bg-yellow-400 w-fit pl-2 rounded-full absolute -bottom-3 text-sm flex gap-2 items-center animate__animated animate__infinite animate__flash">
          <CircleAlert className="size-4" />
          <p>
            إضغط على <b>&quot;X&quot;</b> أسفله لتختفي البوابة
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhatsAppPopup;

import { PropTypes } from "prop-types";
import axios from "axios";
import { API } from "@/config";
const WtpItem = ({ item, openWtp }) => {
  const { full_name, phone, profile_picture } = item;
  return (
    <div
      className="item animate__animated flex gap-4 shadow-md bg-white p-2 rounded-lg cursor-pointer hover:shadow-lg"
      onClick={() => openWtp(phone, `مرحبا ${full_name}!`)}
    >
      <div className="logo-container flex-center">
        <img src={`${API}/uploads/${profile_picture}`} alt={full_name} />
      </div>
      <div className="content">
        <p className="name">{full_name}</p>
        <p className="sub">{phone}</p>
      </div>
      <div className="flex justify-center items-center">
        <SquareArrowOutUpLeft className="text-green-500 text-xl" />
      </div>
    </div>
  );
};

WtpItem.propTypes = {
  item: PropTypes.object.isRequired,
  openWtp: PropTypes.func.isRequired,
};
