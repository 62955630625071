import { useState } from "react";
import { Check, ChevronsUpDown } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import { PropTypes } from "prop-types";
export function Combobox({ areas, onSelect, error }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");

  const handleSelect = (currentValue) => {
    setValue(currentValue === value ? "" : currentValue);
    setOpen(false);
    if (onSelect) {
      const selectedArea = areas.find((area) => area.city === currentValue);
      onSelect(selectedArea);
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={`w-[200px] justify-between ${
            error ? "border-red-500" : ""
          }`}
        >
          {value
            ? areas.find((area) => area.city === value)?.city
            : "اختر مدينة.."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder="اختر مدينة.." className="px-4" />
          <CommandEmpty>لم نعثر على المدينة!</CommandEmpty>
          <CommandList>
            {areas &&
              areas.length > 0 &&
              areas.map((area) => (
                <CommandItem
                  key={area.id}
                  value={area.city}
                  onSelect={handleSelect}
                >
                  <Check
                    className={cn(
                      "ml-2 h-4 w-4",
                      value === area.city ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {area.city}
                </CommandItem>
              ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

Combobox.propTypes = {
  areas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      city: PropTypes.string,
    })
  ),
  onSelect: PropTypes.func,
  error: PropTypes.string,
};
