import Categories from "@/components/sections/Categories";
import DealsCarousel from "@/components/sections/DealsCarousel";
import Products from "@/components/sections/Products";
import Banner from "@/components/sections/Banner";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { API } from "@/config";
import { Testimonial } from "@/components/sections/Testimonial";
import { Button } from "@/components/ui/button";
import { Container } from "@/components/shared/Container";

export default function HomePage() {
  const [isProductLoading, setIsProductLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [productTotal, setProductTotal] = useState(null);
  const [page, setPage] = useState(1);

  const productsSectionRef = useRef(null);

  const scrollToProducts = () => {
    productsSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const fetchproducts = async (page = 1) => {
    console.log("Fetching products...");
    setIsProductLoading(true);
    try {
      const data = {
        params: {
          page,
          perPage: 4,
          visibility: "public",
          is_archived: false,
        },
      };
      const response = await axios.get(
        `${API}/requests/products/read.php`,
        data
      );
      if (response.data.success) {
        // Append new products to the existing list
        setProducts((prevProducts) => [
          ...prevProducts,
          ...response.data.records,
        ]);
        setProductTotal(response.data.total);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsProductLoading(false);
    }
  };

  useEffect(() => {
    if (isProductLoading) {
      fetchproducts();
    }
  }, []);

  const handleLoadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchproducts(nextPage);
  };

  return (
    <>
      <DealsCarousel scrollToProducts={scrollToProducts} />
      <Categories />
      <div ref={productsSectionRef}>
        <Products isLoading={isProductLoading} products={products} />
        {products.length > 0 && (
          <Container>
            <div className="flex w-full justify-center">
              <Button
                className={`text-white bg-primary w-fit px-8 mt-4 hover:text-primary hover:border-2 hover:border-primary ${
                  products.length == productTotal && "hidden"
                }`}
                onClick={handleLoadMore}
                disabled={isProductLoading}
              >
                عرض المزيد
              </Button>
            </div>
          </Container>
        )}
      </div>
      <Banner />
      {/* <NewsLetter /> */}
      <Testimonial />
    </>
  );
}
