import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DefaultLayout from "./layouts/DefaultLayout";
import HomePage from "./pages/HomePage";
import ProductPage from "./pages/ProductPage";
import CartPage from "./pages/CartPage";
import OrderSubmit from "./pages/OrderSubmit";
import TrackingOrder from "./pages/TrackingOrders";
import CategoryPage from "./pages/CategoryPage";
import FAQs from "./pages/footer_pages/FAQs";
import ShippingAndReturns from "./pages/footer_pages/ShippingAndReturns";
import SizeGuide from "./pages/footer_pages/SizeGuide";
import AboutUs from "./pages/footer_pages/AboutUs";
import Careers from "./pages/footer_pages/Careers";
import PrivacyPolicy from "./pages/footer_pages/PrivacyPolicy";
import TermsOfService from "./pages/footer_pages/TermsOfService";
import { useEffect } from "react";
import axios from "axios";
import { API } from "./config";

export default function App() {
  useEffect(() => {
    const fetchAndInjectPixels = async () => {
      try {
        const response = await axios.get(
          `${API}/requests/settings/pixels/read.php`
        );
        if (response.data.success) {
          const {
            facebook_pixel,
            snapchat_pixel,
            tiktok_pixel,
            google_analytics_id,
          } = response.data.pixels;

          // Function to add script tags dynamically
          const addScript = (src, content) => {
            const script = document.createElement("script");
            if (src) {
              script.src = src;
              script.async = true;
            }
            if (content) {
              script.innerHTML = content;
            }
            document.head.appendChild(script);
          };

          // Add Facebook Pixel
          if (facebook_pixel) {
            addScript(
              null,
              `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${facebook_pixel}');
              fbq('track', 'PageView');
            `
            );
          }

          // Add Snapchat Pixel
          if (snapchat_pixel) {
            addScript(
              null,
              `
              !function(e,t,n,s,u,a){
                e.snapi||(e.snapi=function(){
                  (e.snapi.q=e.snapi.q||[]).push(arguments)
                });e.snapi('init', '${snapchat_pixel}');
                var t=t.createElement(n);
                t.async=!0;
                t.src='https://sc-static.net/scevent.min.js';
                e.documentElement.appendChild(t)
              }(window,document,'script'));
            `
            );
          }

          // Add TikTok Pixel
          if (tiktok_pixel) {
            addScript(
              null,
              `
              !function (w, d, t) {
                w.TiktokAnalyticsObject=t;
                var tt=w[t]=w[t]||function(){
                  (tt.q=tt.q||[]).push(arguments)};
                tt.version='1.0';
                var s=d.createElement('script');
                s.src='https://analytics.tiktok.com/i18n/pixel/sdk.js?sdkid=${tiktok_pixel}';
                s.async=!0;
                d.head.appendChild(s);
              }(window, document, 'ttq');
              tt('init', '${tiktok_pixel}');
              tt('track', 'PageView');
            `
            );
          }

          // Add Google Analytics
          if (google_analytics_id) {
            addScript(
              `https://www.googletagmanager.com/gtag/js?id=${google_analytics_id}`
            );
            addScript(
              null,
              `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${google_analytics_id}');
            `
            );
          }
        }
      } catch (error) {
        console.error("Error fetching pixels data:", error);
      }
    };

    fetchAndInjectPixels();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<DefaultLayout />}>
          <Route index element={<HomePage />} />
          <Route path="/category/:link" element={<CategoryPage />} />
          <Route path="/products/:id" element={<ProductPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/order-submit" element={<OrderSubmit />} />
          <Route
            path="/tracking-orders/:tracking_number?"
            element={<TrackingOrder />}
          />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/shipping-returns" element={<ShippingAndReturns />} />
          <Route path="/order-tracking" element={<TrackingOrder />} />
          <Route path="/size-guide" element={<SizeGuide />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
        </Route>
      </Routes>
    </Router>
  );
}
