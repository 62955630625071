import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { CheckIcon, PackageIcon, TruckIcon } from "lucide-react";
import trackingVector from "../assets/tracking-vector.svg";
import { useEffect, useState } from "react";
import { API } from "@/config";
export default function TrackingOrder() {
  const { tracking_number } = useParams();
  const [trackingNumber, setTrackingNumber] = useState("");
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    setTrackingNumber(e.target.value);
  };

  useEffect(() => {
    if (tracking_number && tracking_number.trim() !== "") {
      setTrackingNumber(tracking_number);
      fetchOrderByTrackingNumber(tracking_number);
    }
  }, [tracking_number]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    fetchOrderByTrackingNumber();
  };

  async function fetchOrderByTrackingNumber(_trackingNumber = trackingNumber) {
    console.log({ _trackingNumber });
    try {
      const response = await axios.get(
        `${API}/requests/orders/getOrderByTrackingNumber.php?trackingnumber=${_trackingNumber}`
      );

      const data = response.data;
      console.log(data);

      if (data.success) {
        setOrder(data);
      } else {
        setError(data.message || "Order not found");
      }
    } catch (err) {
      setError("An error occurred while fetching the order data");
    } finally {
      setLoading(false);
    }
  }
  return (
    <main>
      <Container className="bg-muted bg-gray-50">
        <div className="grid gap-6 lg:grid-cols-[1fr_400px] lg:gap-12 xl:grid-cols-[1fr_600px]">
          <div className="flex flex-col justify-center space-y-4">
            <div className="space-y-2">
              <h1 className="text-2xl font-bold tracking-tighter sm:text-3xl xl:text-4xl/none">
                تتبع طلبك
              </h1>
              <p className="max-w-[600px] text-muted-foreground md:text-lg">
                أدخل رقم طلبك للتحقق من حالة التسليم.
              </p>
            </div>
            <form className="flex gap-2" onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder="أدخل رقم طلبك"
                value={trackingNumber}
                onChange={handleInputChange}
                className="max-w-lg flex-1 rounded-md shadow-sm border-gray-300 focus:border-primary focus:ring-primary"
              />
              <Button
                type="submit"
                className="rounded-md shadow-sm text-white hover:text-green-600"
                disabled={loading}
              >
                {loading ? "جارٍ التتبع..." : "تتبع الطلب"}
              </Button>
            </form>
            {error && <p className="text-red-500">{error}</p>}
          </div>
          {order ? (
            <OrderHistory order={order} />
          ) : (
            <img
              src={trackingVector}
              width="550"
              height="550"
              alt="Hero"
              className="mx-auto aspect-video overflow-hidden rounded-xl object-contain sm:w-full lg:order-last lg:aspect-square"
            />
          )}
        </div>
      </Container>
      <Container>
        <div className="flex flex-col items-center justify-center space-y-4 text-center">
          <div className="space-y-2">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">
              عملية استيفاء الطلبات
            </h2>
            <p className="max-w-[900px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
              تتبع طلبك أثناء تقدمه خلال مراحل استيفاء الطلب.
            </p>
          </div>
        </div>
        <div className="mx-auto grid max-w-5xl items-center gap-6 py-12 lg:grid-cols-3 lg:gap-12">
          <div className="grid gap-1">
            <div className="flex items-center gap-2">
              <TruckIcon className="h-6 w-6 text-primary" />
              <h3 className="text-xl font-bold">تم تقديم الطلب</h3>
            </div>
            <p className="text-muted-foreground">
              تم استلام طلبك وجارٍ معالجته الآن.
            </p>
          </div>
          <div className="grid gap-1">
            <div className="flex items-center gap-2">
              <PackageIcon className="h-6 w-6 text-primary" />
              <h3 className="text-xl font-bold">جارٍ تجهيز الشحنة</h3>
            </div>
            <p className="text-muted-foreground">جارٍ تجهيز طلبك للشحن.</p>
          </div>
          <div className="grid gap-1">
            <div className="flex items-center gap-2">
              <TruckIcon className="h-6 w-6 text-primary" />
              <h3 className="text-xl font-bold">في الطريق</h3>
            </div>
            <p className="text-muted-foreground">
              تم شحن طلبك وهو في الطريق إليك.
            </p>
          </div>
          <div className="grid gap-1">
            <div className="flex items-center gap-2">
              <CheckIcon className="h-6 w-6 text-primary" />
              <h3 className="text-xl font-bold">تم التوصيل</h3>
            </div>
            <p className="text-muted-foreground">تم تسليم طلبك بنجاح.</p>
          </div>
        </div>
      </Container>

      <Testimonial />
    </main>
  );
}

import PropTypes from "prop-types";
const OrderHistory = ({ order }) => {
  return (
    <div className="w-full px-4 py-8">
      <div className="flex flex-col">
        {/* Order Creation */}
        <OrderHistoryItem
          label="Order Created"
          historyDate={order.created_at}
          source={order.source}
          comment=""
          isLast={order.status_changes.length == 0}
        />

        {/* Dynamic Status Changes */}
        {order.status_changes.map((status, index) => (
          <OrderHistoryItem
            key={index}
            label={status.new_status}
            historyDate={status.change_date}
            source={status.changed_by}
            comment={status.comment}
            isLast={index == order.status_changes.length - 1}
          />
        ))}

        {/* Ramassage Date */}
        {order.ramassage_date && (
          <div className="flex items-center">
            <div className="w-8 h-8 bg-yellow-500 text-white rounded-full flex items-center justify-center">
              {order.status_changes.length + 2}
            </div>
            <div className="ml-4">
              <h4 className="font-bold">Ramassage Date</h4>
              <p className="text-sm text-gray-600">{order.ramassage_date}</p>
            </div>
          </div>
        )}

        {/* Delivery Date */}
        {order.delivery_date && (
          <div className="flex items-center">
            <div className="w-8 h-8 bg-purple-500 text-white rounded-full flex items-center justify-center">
              {order.status_changes.length + (order.ramassage_date ? 3 : 2)}
            </div>
            <div className="ml-4">
              <h4 className="font-bold">Delivery Date</h4>
              <p className="text-sm text-gray-600">{order.delivery_date}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
OrderHistory.propTypes = {
  order: PropTypes.object.isRequired,
};

import { formatDistanceToNow } from "date-fns";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Container } from "@/components/shared/Container";
import { Testimonial } from "@/components/sections/Testimonial";
const OrderHistoryItem = ({ label, historyDate, isLast }) => {
  const formatTimeAgo = (date) => {
    return formatDistanceToNow(new Date(date), { addSuffix: true });
  };
  const formatDate = formatTimeAgo(historyDate);
  return (
    <div className="relative flex items-start ">
      <div className=" flex-shrink-0 ">
        <div className="h-8 w-8 flex items-center justify-center rounded-full bg-gray-500 text-white relative z-10">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
        {!isLast && (
          <div className="absolute top-0 right-4 h-full w-0.5 bg-gray-200" />
        )}
      </div>
      <div className="mr-4 w-full pb-8 ">
        <div className="flex justify-between w-full items-center">
          <h4 className="font-bold text-lg">{label}</h4>
          <p className="text-sm text-gray-600">{formatDate}</p>
        </div>
        {/* <p className="text-sm text-gray-900 font-medium">{source}</p> */}
        {/* <p className="text-xs text-gray-500">{comment}</p> */}
      </div>
    </div>
  );
};
OrderHistoryItem.propTypes = {
  label: PropTypes.string.isRequired,
  historyDate: PropTypes.string.isRequired,
  isLast: PropTypes.bool.isRequired,
};
