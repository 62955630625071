import { Button } from "@/components/ui/button";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { API } from "@/config";
import { Container } from "../shared/Container";
import { Skeleton } from "../ui/skeleton";
import notfound from "@/assets/box.svg";

// import { products } from "../../data/products.json";

export default function Products({ isLoading, products }) {
  return (
    <Container>
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-2xl font-bold">المنتجات</h2>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-8">
        {isLoading ? (
          Array.from({ length: 8 }).map((_, index) => (
            <Skeleton key={index} style={{ aspectRatio: "300/300" }} />
          ))
        ) : products.length > 0 ? (
          products.map((product, index) => (
            <ProductItem
              key={index} // Ensure each Product has a unique key
              name={product.title}
              imageSrc={
                product.thumbnail_url
                  ? `${API}/uploads/${product.thumbnail_url}`
                  : "/noproducts.svg"
              }
              description={product.subtitle}
              discount={(
                ((product.comparing_price - product.price) * 100) /
                product.comparing_price
              ).toFixed(0)}
              // reviews={5}
              price={product.price}
              id={product.id}
            />
          ))
        ) : (
          <div className="flex justify-center text-center pb-12">
            <div className="continer">
              <img src={notfound} />
              <div className="content">
                <h1 className="text-2xl text-center font-extrabold mb-4">
                  لم يتم العثور على منتجات
                </h1>
                <div className="text-sm text-gray-600">
                  <p> نأسف، ولكن لا توجد منتجات .</p>
                  <br />
                  <p> يرجى التحقق لاحقًا.</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
}
Products.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
};

export const ProductItem = ({
  name,
  imageSrc,
  description,
  discount,
  // reviews,
  price,
  id,
}) => {
  return (
    <Link
      to={`/products/${id}`}
      className="bg-white rounded-md shadow-md overflow-hidden relative group transition-all duration-300 ease-in-out hover:shadow-xl hover:ring-2 hover:ring-primary"
    >
      <div className="relative">
        <img
          alt="Product"
          className="w-full h-auto"
          src={imageSrc}
          style={{
            aspectRatio: "300/300",
            objectFit: "cover",
          }}
        />
        {discount && (
          <div className="absolute top-2 left-2 bg-red-500 text-white px-2 py-1 rounded-md text-sm font-semibold transform ">
            {discount}%
          </div>
        )}
      </div>
      <div className="p-4 space-y-2">
        <h3 className="text-sm md:text-xl font-semibold">{name}</h3>
        <p className="text-gray-500 text-xs md:text-sm">{description}</p>
        {/* <div className="flex items-center space-x-2">
          <div className="flex items-center space-x-1 text-yellow-500">
            {Array.from({ length: 5 }, (_, index) => (
              <FaRegStar key={index} className="h-4 w-4" />
            ))}
          </div>
          <span className="text-gray-500 text-sm">({reviews} reviews)</span>
        </div> */}
        <div className="flex items-center justify-between flex-col md:flex-row gap-2">
          <span className="text-2xl font-bold text-primary">دم{price}</span>
          <Button className="text-white hover:text-primary w-full">
            اشترِ الآن
          </Button>
        </div>
      </div>
    </Link>
  );
};

ProductItem.propTypes = {
  name: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  discount: PropTypes.string,
  price: PropTypes.any.isRequired,
  id: PropTypes.number.isRequired,
};
