import { Container } from "@/components/shared/Container";

function TermsOfService() {
  return (
    <Container>
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-center mb-8">الشروط والأحكام</h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. مقدمة</h2>
          <p className="text-lg leading-relaxed">
            هذه الشروط والأحكام تحكم استخدامك لموقع{" "}
            <span className="font-bold">Moda Mix Trends</span>{" "}
            (www.modamixtrends.com). من خلال الوصول إلى الموقع أو استخدامه، فإنك
            توافق على الالتزام بهذه الشروط. إذا كنت لا توافق على هذه الشروط،
            فيجب عليك التوقف عن استخدام الموقع فورًا.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            2. الحسابات والمسؤوليات
          </h2>
          <p className="text-lg leading-relaxed">
            عند إنشاء حساب على موقعنا، يجب عليك تقديم معلومات دقيقة وكاملة. أنت
            مسؤول عن الحفاظ على سرية حسابك وكلمة المرور الخاصة بك، وتتحمل
            مسؤولية جميع الأنشطة التي تحدث تحت حسابك.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. الخصوصية</h2>
          <p className="text-lg leading-relaxed">
            نحن نحترم خصوصيتك ونلتزم بحماية بياناتك الشخصية. استخدامك للموقع
            يعني موافقتك على سياستنا الخاصة بالخصوصية، التي يمكنك العثور عليها
            في قسم الخصوصية.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. شروط الشراء والدفع</h2>
          <p className="text-lg leading-relaxed">
            جميع الطلبات التي تتم عبر الموقع تخضع لتوافر المنتجات وقبول الطلب.
            نحن نحتفظ بالحق في رفض أي طلب لأي سبب من الأسباب. جميع الأسعار على
            الموقع نهائية وتشمل الضرائب المطبقة. يجب إجراء الدفع عبر الوسائل
            المقبولة على الموقع.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            5. التعديلات على الشروط
          </h2>
          <p className="text-lg leading-relaxed">
            نحن نحتفظ بالحق في تعديل هذه الشروط في أي وقت. سيتم نشر التعديلات
            على هذه الصفحة، واستخدامك المستمر للموقع بعد أي تعديل يعني موافقتك
            على الشروط المعدلة.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">6. حدود المسؤولية</h2>
          <p className="text-lg leading-relaxed">
            نحن لسنا مسؤولين عن أي أضرار قد تنشأ عن استخدامك للموقع أو عدم
            القدرة على استخدامه. يشمل ذلك، على سبيل المثال لا الحصر، الأضرار
            المباشرة وغير المباشرة والخسائر الناتجة عن الفقدان أو الأضرار.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">7. القانون الحاكم</h2>
          <p className="text-lg leading-relaxed">
            هذه الشروط والأحكام تخضع وتفسر وفقًا لقوانين المملكة المغربية. أي
            نزاع ينشأ عن هذه الشروط يجب أن يحال إلى المحاكم المختصة في المملكة
            المغربية.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">8. اتصل بنا</h2>
          <p className="text-lg leading-relaxed">
            إذا كان لديك أي أسئلة أو استفسارات حول هذه الشروط، يمكنك الاتصال بنا
            عبر البريد الإلكتروني:
            <a
              href="mailto:contact@modamixtrends.com"
              className="text-blue-600 font-bold"
            >
              {" "}
              contact@modamixtrends.com
            </a>
            أو الهاتف:{" "}
            <a href="tel:+212620161140" className="text-blue-600 font-bold">
              {" "}
              0620161140
            </a>
            .
          </p>
        </section>
      </div>
    </Container>
  );
}

export default TermsOfService;
