import { Container } from "@/components/shared/Container";

function ShippingAndReturns() {
  return (
    <Container>
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-center mb-8">الشحن والإرجاع</h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">الشحن</h2>
          <p className="text-lg leading-relaxed">
            في <span className="font-bold">Moda Mix Trends</span>، نقدم خدمة
            التوصيل السريع خلال 24 ساعة مجاناً لجميع الطلبات.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            الإرجاع واستبدال المنتجات
          </h2>
          <p className="text-lg leading-relaxed">
            إذا كنت ترغب في استبدال منتج تم شراؤه، يمكنك القيام بذلك خلال 48
            ساعة من استلام الطلب. يرجى ملاحظة أنه سيتم إضافة رسوم قدرها 30 درهم
            مغربي مقابل الخدمة.
          </p>
          <p className="text-lg leading-relaxed">
            يجب عليك إعادة المنتج بنفس الحالة التي استلمتها، بما في ذلك التغليف
            وجميع الملحقات المرفقة.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">الاتصال بنا</h2>
          <p className="text-lg leading-relaxed">
            إذا كانت لديك أي أسئلة حول الشحن أو الاستبدال، يرجى الاتصال بنا عبر
            الرقم التالي:
            <a href="tel:0620161140" className="text-blue-600 font-bold">
              {" "}
              0620161140
            </a>
            .
          </p>
        </section>
      </div>
    </Container>
  );
}

export default ShippingAndReturns;
