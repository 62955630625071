import {
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenu,
} from "@/components/ui/dropdown-menu";
import { Link } from "react-router-dom";

// import { categories } from "../../data/categories.json";
// import { cartItems } from "../../data/cart.json";

export default function Header() {
  return (
    <header className="bg-white text-gray-900 py-4 px-4 md:px-12 shadow-md sticky w-full top-0 left-0 right-0 z-50">
      <div className="container mx-auto max-w-6xl flex items-center justify-between h-full px-0 md:px-6">
        <div className="flex items-center gap-4">
          <Link to="/">
            <Codesandbox className="h-6 w-6" />
          </Link>
          <Link to="/">متجري</Link>
          <nav className="flex items-center gap-6">
            <CategoriesDropdown />
            <Link
              to="/tracking-orders"
              className="hover:text-gray-600 transition-colors"
            >
              تتبع الطلب
            </Link>
          </nav>
        </div>

        <div className="flex items-center space-x-4">
          <CartSheet />
        </div>
      </div>
    </header>
  );
}

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { API } from "@/config";
import axios from "axios";
import { Skeleton } from "../ui/skeleton";
import { CartSheet } from "../shared/CartSheet";
import { ChevronDown, Codesandbox } from "lucide-react";
const CategoryDropdownItem = ({
  link,
  title,
  subtitle,
  thumbnail,
  onClose,
}) => {
  return (
    <Link
      to={link}
      onClick={onClose} // Call the function when the link is clicked
      className="flex flex-row-reverse justify-start items-center gap-4 group hover:bg-gray-50 cursor-pointer rounded-md"
    >
      <img
        alt={title}
        className="rounded-md"
        src={`${API}/uploads/${thumbnail}`}
        style={{
          aspectRatio: "50/50",
          objectFit: "cover",
        }}
        width="50"
        height="50"
      />
      <div className="text-end">
        <h1 className="block font-semibold group-hover:text-gray-600 transition-colors">
          {title}
        </h1>
        <p className="text-xs text-gray-500 group-hover:text-gray-600 transition-colors">
          {subtitle}
        </p>
      </div>
    </Link>
  );
};
CategoryDropdownItem.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

const CategoriesDropdown = () => {
  const [categories, setcategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const closeDropdown = () => setIsOpen(false);

  useEffect(() => {
    const fetchcategories = async () => {
      try {
        const response = await axios.get(`${API}/requests/categories/read.php`);
        setcategories(response.data.records);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchcategories();
  }, []);

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger
        className="flex items-center gap-2 hover:text-gray-600 transition-colors"
        onClick={toggleDropdown}
      >
        <ChevronDown className="h-4 w-4" />
        <span>الأصناف</span>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="bg-white text-gray-800 rounded-md shadow-lg max-w-96 md:max-w-[500px]"
        align="end"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
          {isLoading ? (
            <>
              <Skeleton className="w-16 h-16 rounded-md ring-1" />
              <Skeleton className="w-16 h-16 rounded-md ring-1" />
              <Skeleton className="w-16 h-16 rounded-md ring-1" />
              <Skeleton className="w-16 h-16 rounded-md ring-1" />
            </>
          ) : (
            categories.map((category, index) => (
              <CategoryDropdownItem
                key={index} // Ensure each CategoryDropdownItem has a unique key
                link={`/category/${category.link}`}
                title={category.title}
                thumbnail={category.thumbnail}
                subtitle={category.subtitle}
                onClose={closeDropdown} // Passing the function as a prop
              />
            ))
          )}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
