import { Container } from "@/components/shared/Container";

function AboutUs() {
  return (
    <Container>
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-center mb-8">
          مرحبًا بكم في Moda Mix Trends
        </h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">تقديم</h2>
          <p className="text-lg leading-relaxed">
            نرحب بكم في متجرنا الإلكتروني{" "}
            <span className="font-bold">Moda Mix Trends</span>، حيث نقدم لكم
            تشكيلة واسعة من الملابس لكافة الأذواق والمناسبات.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">قصتنا ورؤيتنا</h2>
          <p className="text-lg leading-relaxed">
            تأسست <span className="font-bold">Moda Mix Trends</span> من شغف عميق
            بعالم الموضة واهتمام كبير بجودة الملابس. نحن نسعى لتقديم تجربة تسوق
            فريدة، تجمع بين الأناقة والراحة.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">منتجاتنا والجودة</h2>
          <p className="text-lg leading-relaxed">
            نقدم تشكيلة متنوعة من الملابس تشمل الأزياء الرجالية والنسائية
            والأطفالية. كل قطعة من منتجاتنا تم اختيارها بعناية فائقة لضمان أعلى
            مستويات الجودة والأناقة.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">التزامنا تجاه العملاء</h2>
          <p className="text-lg leading-relaxed">
            في <span className="font-bold">Moda Mix Trends</span>، نضع رضا
            العملاء في المقام الأول. نحرص على تقديم خدمة عملاء استثنائية تلبي
            احتياجاتكم وتتجاوز توقعاتكم.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">المجتمع والقيم</h2>
          <p className="text-lg leading-relaxed">
            نحن نؤمن بأهمية دعم المجتمع المحلي والمساهمة في تحقيق التنمية
            المستدامة. قيمنا تشمل الشفافية والاحترام والاستدامة.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">دعوة للتفاعل</h2>
          <p className="text-lg leading-relaxed">
            ندعوكم لاستكشاف موقعنا لتجربة تسوق فريدة والاطلاع على تشكيلتنا
            المتنوعة من الملابس.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">الختام</h2>
          <p className="text-lg leading-relaxed">
            شكرًا لكم على زيارتكم لمتجرنا الإلكتروني{" "}
            <span className="font-bold">Moda Mix Trends</span>. نتطلع لخدمتكم
            وتلبية احتياجاتكم دائمًا.
          </p>
        </section>
      </div>
    </Container>
  );
}

export default AboutUs;
