export const CreatingOrder = () => {
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center flex items-center gap-4">
        <div className="animate-spin rounded-full border-4 border-primary border-t-transparent h-6 w-6" />
        <p>طلبك قيد الإنشاء..</p>
      </div>
    </div>
  );
};
