import { UserRound } from "lucide-react";
import { Container } from "../shared/Container";

export const Testimonial = () => {
  const comments = [
    {
      username: "وسيم الفن",
      role: "زبون",
      comment:
        "كانت خدمة العملاء التي تلقيتها استثنائية. فريق الدعم تفاعل مع مخاوفي بشكل أكبر من المتوقع.",
    },
    {
      username: "بدر الطاهري",
      role: "زبون",
      comment:
        "كنت مترددًا في البداية، لكن المنتج فاق توقعاتي. الجودة ممتازة والتسليم كان سريعًا بشكل لا يصدق.",
    },
    {
      username: "وليد بناني",
      role: "زبون",
      comment: "ليست المرة الاولى و لن تكون الآخرة، خدمة العملاء جيدة جدا",
    },
  ];
  return (
    <Container className="bg-muted bg-gray-50">
      <div className="flex flex-col items-center justify-center space-y-4 text-center">
        <div className="space-y-2">
          <h2 className="text-3xl font-bold tracking-tighter">
            ما يقوله عملاؤنا
          </h2>
          <p className="max-w-[900px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
            اسمع من عملائنا الراضين عن تجربتهم مع خدماتنا.
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full">
          {comments.map((comment, index) => (
            <CommentItem key={index} data={comment} />
          ))}
        </div>
      </div>
    </Container>
  );
};
import { PropTypes } from "prop-types";
const CommentItem = ({ data }) => {
  const { username, role, comment } = data;
  return (
    <div className="bg-background p-6 rounded-lg shadow-sm">
      <blockquote className="text-lg font-semibold leading-snug">
        {comment}
      </blockquote>
      <div className="flex items-center gap-4 mt-4">
        <div className="size-14 bg-slate-100 rounded-full flex-center">
          <UserRound className="size-12 opacity-20" />
        </div>
        <div>
          <div className="font-semibold">{username}</div>
          <div className="text-sm text-muted-foreground">{role}</div>
        </div>
      </div>
    </div>
  );
};
CommentItem.propTypes = {
  data: PropTypes.object.isRequired,
};
