import { Button } from "@/components/ui/button";
import { Container } from "../shared/Container";

const deals = [
  {
    title: "اكتشف مجموعة واسعة من المنتجات المميزة بأسعار تنافسية",
    imageSrc: "/deals.png",
  },
  {
    title: "Big Sale: Up to 50% off on clothing!",
    imageSrc: "/deals.png",
  },
  {
    title: "Home Decor Flash Sale: Save 40% now!",
    imageSrc: "/deals.png",
  },
];

import { PropTypes } from "prop-types";
export default function DealsCarousel({ scrollToProducts }) {
  return (
    <Container className="bg-yellow-50 overflow-hidden">
      <div className="flex flex-row items-center justify-between relative h-96">
        <div className="flex flex-col items-start justify-center text-primary space-y-4 min-w-96 relative z-10">
          <h2 className="text-xl md:text-3xl font-bold max-w-80">
            {deals[0].title}
          </h2>
          <Button
            onClick={scrollToProducts}
            className="bg-primary-foreground hover:bg-primary-foreground text-white px-6 py-3 rounded-md"
          >
            اشتر الآن
          </Button>
        </div>
        <img
          alt="Carousel Image"
          className="rounded-lg absolute md:relative top-0 left-0 h-full md:w-96 w-full object-cover"
          src={deals[0].imageSrc}
          style={{
            aspectRatio: "1/1",
            objectFit: "cover",
          }}
        />
      </div>
    </Container>
  );
}

DealsCarousel.propTypes = {
  scrollToProducts: PropTypes.func.isRequired,
};
