import { Container } from "@/components/shared/Container";

function SizeGuide() {
  return (
    <Container>
      <h1 className="text-3xl font-bold mb-6">دليل المقاسات</h1>
      <div className="space-y-4">
        <p>
          استخدم الجداول أدناه للعثور على المقاس المناسب لك. إذا كنت بين
          المقاسات، نوصي بطلب المقاس الأكبر.
        </p>
        <div>
          <h2 className="text-xl font-semibold">مقاسات الرجال</h2>
          <table className="w-full mt-4">
            <thead>
              <tr>
                <th className="border px-4 py-2">المقاس</th>
                <th className="border px-4 py-2">الوزن (كغ)</th>
                <th className="border px-4 py-2">الطول (سم)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-4 py-2">M</td>
                <td className="border px-4 py-2">55 - 65</td>
                <td className="border px-4 py-2">1.65 - 1.67</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">L</td>
                <td className="border px-4 py-2">65 - 70</td>
                <td className="border px-4 py-2">1.68 - 1.72</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">XL</td>
                <td className="border px-4 py-2">70 - 75</td>
                <td className="border px-4 py-2">1.73 - 1.77</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">2XL</td>
                <td className="border px-4 py-2">75 - 85</td>
                <td className="border px-4 py-2">1.78 - 1.81</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">3XL</td>
                <td className="border px-4 py-2">85 - 98</td>
                <td className="border px-4 py-2">1.82 - 1.85</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">4XL</td>
                <td className="border px-4 py-2">98 - 105</td>
                <td className="border px-4 py-2">1.86 - 1.89</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">5XL</td>
                <td className="border px-4 py-2">106 - 115</td>
                <td className="border px-4 py-2">1.90 - 1.92</td>
              </tr>
              <tr>
                <td className="border px-4 py-2">6XL</td>
                <td className="border px-4 py-2">116 - 125</td>
                <td className="border px-4 py-2">1.93 - 1.95</td>
              </tr>
              {/* Add more sizes as needed */}
            </tbody>
          </table>
        </div>
      </div>
    </Container>
  );
}

export default SizeGuide;
