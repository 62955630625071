import { PropTypes } from "prop-types";
export const Container = ({ children, className }) => {
  return (
    <section className={`py-2 md:py-8 px-2 md:px-6 ${className}`}>
      <div className="container mx-auto max-w-6xl  h-full px-2 md:px-6">
        {children}
      </div>
    </section>
  );
};
Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
