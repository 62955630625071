import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
// import {
//   SelectValue,
//   SelectTrigger,
//   SelectItem,
//   SelectContent,
//   Select,
// } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { API } from "@/config";
import { useCart } from "@/context/CartContext";
import { CartItem } from "@/components/shared/CartSheet";
import { Container } from "@/components/shared/Container";
import { toast } from "react-toastify";
import { CreatingOrder } from "@/components/shared/CreatingOrder";
import { TriangleAlert } from "lucide-react";
import { Combobox } from "@/components/shared/Combobox";

export default function CartPage() {
  const navigate = useNavigate();
  const { cartItems, removeItemFromCart, totalPrice } = useCart();
  const [orderCreating, setOrderCreating] = useState(false);

  const [shippingInfo, setShippingInfo] = useState({
    name: "",
    phone: "",
    city: "",
    address: "",
    message: "",
  });

  const [validationErrors, setValidationErrors] = useState({});

  const [deliveryPrice, setDeliveryPrice] = useState(0);

  const [areas, setAreas] = useState([]); // State to hold areas/cities

  useEffect(() => {
    fetchAreas(); // Fetch areas when component mounts
  }, []);

  console.log({ areas });
  /*const fetchAreas = async () => {
    try {
      const response = await axios.get(
        "https://api.forcelog.ma/customer/Cities",
        {
          headers: {
            "X-API-Key": "a89cc890bec9ece82415bb6635f8e452",
          },
        }
      );
      console.log("Response:", response.data);
      const cities = Object.values(response.data.Cities).map((city) => ({
        id: city.CODE,
        city: city.NAME,
        delivery_price: 0,
      }));
      console.log("Cities:", cities);
      setAreas(cities);
    } catch (error) {
      console.error("Error fetching areas:", error.message);
    }
  };*/

  const fetchAreas = async () => {
    try {
      const response = await axios.get(`${API}/requests/areas/read.php`);
      if (response.data.success) {
        setAreas(response.data.records);
      } else {
        console.error("Failed to fetch areas:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching areas:", error.message);
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setShippingInfo((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    // Clear validation error for the input field
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [id]: null,
    }));
  };

  /*
  use this if you don't want to use Combobox component
  const handleSelectChange = (value) => {
    setShippingInfo((prevState) => ({
      ...prevState,
      city: value,
    }));
    const area = areas.find((area) => area.city === value);
    setDeliveryPrice(area ? parseFloat(area.delivery_price) : 0);

    // Clear validation error for the city select
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      city: null,
    }));
  };*/

  const handleSelectChange = (cityObj) => {
    console.log("Selected city:", cityObj);
    setShippingInfo((prevState) => ({
      ...prevState,
      city: cityObj.city,
    }));
    setDeliveryPrice(parseFloat(cityObj.delivery_price));

    // Clear validation error for the city select
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      city: null,
    }));
  };

  console.log({ shippingInfo });

  const handleCompleteOrder = async () => {
    const errors = {};

    if (!shippingInfo.name) {
      errors.name = "هذا الحقل مطلوب";
    }
    if (!shippingInfo.phone) {
      errors.phone = "هذا الحقل مطلوب";
    }
    if (!shippingInfo.city) {
      errors.city = "هذا الحقل مطلوب";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      toast.error("يرجى ملء جميع الحقول المطلوبة");
      return;
    }

    if (!validatePhoneNumber(shippingInfo.phone)) {
      setValidationErrors({
        phone:
          "رقم الهاتف غير صالح! تأكد من رقم الهاتف، يجب ان يبتدأ ب 06 أو 05 أو 07 و يحتوي على 10 ارقام ",
      });
      toast.error("رقم الهاتف غير صالح");
      return;
    }
    setValidationErrors({});

    setOrderCreating(true);

    const data = {
      ...shippingInfo,
      items: cartItems,
      source: "shop",
      delivery_price: deliveryPrice,
      total_price: totalPrice,
      status: "pending",
    };
    console.log("Order data:", data);

    try {
      const response = await axios.post(
        `${API}/requests/orders/create.php`,
        data
      );
      console.log("Response:", response.data);
      if (!response.data.success) {
        toast.error("حدث خطأ أثناء إنشاء الطلب");
      } else {
        toast.success("تم إنشاء الطلب بنجاح");
        localStorage.setItem("order", JSON.stringify(data));
        console.log("Order created:", response.data);
        const order = response.data;
        console.log("Order:", order);
        const trackingNumber = order.tracking_number;
        console.log("Tracking number:", trackingNumber);
        localStorage.setItem("trackingNumber", trackingNumber);
        navigate("/order-submit");
      }
    } catch (error) {
      console.error("Error creating order:", error);
    } finally {
      setOrderCreating(false);
    }
  };

  const removeItem = (indexToRemove) => {
    removeItemFromCart(indexToRemove);
  };

  const validatePhoneNumber = (number) => {
    const phonePattern = /^(05|06|07)[0-9]{8}$/;
    return phonePattern.test(number);
  };

  return (
    <Container>
      <div className="grid gap-6 md:grid-cols-2 lg:gap-12 items-start max-w-6xl mx-auto">
        <div className="grid gap-4 md:gap-10 items-start">
          <div className="grid gap-4">
            <h1 className="font-bold text-2xl sm:text-3xl">
              سلة التسوق الخاصة بك
            </h1>
            <p className="text-gray-900 flex gap-4 p-4 rounded-full bg-yellow-300">
              <TriangleAlert />
              <span className="flex gap-2">
                املأ الاستمارة و انقر على <b> تأكيد الشراء</b>
              </span>
            </p>
          </div>
          <div className="grid gap-4">
            <div className="grid gap-2">
              <Label className="text-sm" htmlFor="name">
                الاسم الكامل *
              </Label>
              <Input
                id="name"
                placeholder="مثال: محمد"
                required
                onChange={handleInputChange}
                className={validationErrors.name ? "border-red-500" : ""}
              />
              {validationErrors.name && (
                <p className="text-red-500 text-sm">{validationErrors.name}</p>
              )}
            </div>
            <div className="grid gap-2">
              <Label className="text-sm" htmlFor="phone">
                رقم الهاتف *
              </Label>
              <Input
                id="phone"
                placeholder="مثال: 0600000000"
                required
                onChange={handleInputChange}
                className={validationErrors.phone ? "border-red-500" : ""}
              />
              {validationErrors.phone && (
                <p className="text-red-500 text-sm">{validationErrors.phone}</p>
              )}
            </div>
            <div className="grid gap-2">
              <Label className="text-sm" htmlFor="city">
                المدينة *
              </Label>
              <Combobox
                areas={areas}
                onSelect={handleSelectChange}
                error={validationErrors.city}
              />
              {validationErrors.city && (
                <p className="text-red-500 text-sm">{validationErrors.city}</p>
              )}

              {/* <Select onValueChange={handleSelectChange}>
                <SelectTrigger
                  aria-label="Select a city"
                  id="city"
                  dir="rtl"
                  className={validationErrors.city ? "border-red-500" : ""}
                >
                  <SelectValue placeholder="اختر مدينة" />
                </SelectTrigger>
                <SelectContent>
                  {areas.map((area) => (
                    <SelectItem key={area.id} value={area.city} dir="rtl">
                      {area.city}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {validationErrors.city && (
                <p className="text-red-500 text-sm">{validationErrors.city}</p>
              )} */}
            </div>
            <div className="grid gap-2">
              <Label className="text-sm" htmlFor="address">
                العنوان
              </Label>
              <Textarea
                id="address"
                placeholder="مثال: حي المدارة، الزنقة 14، الباب 02"
                onChange={handleInputChange}
              />
            </div>
            <div className="grid gap-2">
              <Label className="text-sm" htmlFor="message">
                رسالة
              </Label>
              <Textarea
                id="message"
                placeholder="اترك ملاحظة للبائع"
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="grid gap-6 md:gap-8 relative border-2 border-gray-100 p-4 rounded-2xl">
          <div className="flex gap-4 items-center border-b-2 pb-4">
            <h3 className="text-lg font-semibold">سلة التسوق</h3>
            <span className="text-gray-500 ">({cartItems.length} منتجات)</span>
          </div>
          <div className="space-y-4">
            {cartItems.length > 0 ? (
              cartItems.map((item, index) => (
                <div key={index} className="space-y-4">
                  <CartItem
                    imageSrc={
                      item.thumbnail_url
                        ? `${API}/uploads/${item.thumbnail_url}`
                        : "/noproducts.svg"
                    }
                    name={item.title}
                    quantity={parseInt(item.quantity)}
                    price={parseFloat(item.price)}
                    onRemove={() => removeItem(index)}
                    selectedVariants={item.selectedVariants}
                  />
                  {index < cartItems.length - 1 && <hr />}
                </div>
              ))
            ) : (
              <div className="flex items-center justify-center">
                <p className="text-gray-500">لا توجد منتجات في سلة التسوق</p>
              </div>
            )}
          </div>
          <div className="block bg-gray-100 p-4 pt-0 w-full">
            <div className="mt-4 flex items-center justify-between">
              <span className="text-gray-500">سعر المنتجات:</span>
              <span className="font-semibold">{`دم${totalPrice.toFixed(
                2
              )}`}</span>
            </div>
            <div className="mt-4 flex items-center justify-between">
              <span className="text-gray-500">سعر التوصيل:</span>
              <span className="font-semibold">{`دم${deliveryPrice}`}</span>
            </div>
            <hr className="mt-4 border-gray-500 border-dashed border-1" />
            <div className="mt-4 flex items-center justify-between text-black font-bold text-lg">
              <span>المجموع الكلي:</span>
              <span>{`دم${(totalPrice + deliveryPrice).toFixed(2)}`}</span>
            </div>
            <br />
            {cartItems.length > 0 ? (
              <div className="mt-6 flex justify-center items-center flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 fixed bottom-safe-bottom right-0 left-0 p-2 md:relative bg-white w-full">
                <Button
                  className="bg-gradient-to-r w-9/12 px-12 from-yellow-400 to-yellow-600 text-black animate__animated animate__pulse animate__infinite"
                  onClick={handleCompleteOrder}
                >
                  تأكيد الشراء
                </Button>

                {/* <Button variant="outline" onClick={addToCart}>
                أضف إلى السلة
              </Button> */}
              </div>
            ) : null}
            <Button
              asChild
              type="cancel"
              className="w-full bg-transparent border-2 border-primary text-primary"
            >
              <Link to="/">متابعة التسوق</Link>
            </Button>
          </div>
        </div>
      </div>

      {orderCreating && <CreatingOrder />}
    </Container>
  );
}
