import { Link } from "react-router-dom";
import { Container } from "../shared/Container";
import { FaInstagram, FaTiktok } from "react-icons/fa6";
import { Phone } from "lucide-react";

function Footer() {
  return (
    <footer className="bg-gray-800 text-white">
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* معلومات الاتصال */}
          <div>
            <h4 className="text-xl font-semibold mb-4">اتصل بنا</h4>
            <p>البريد الإلكتروني: contact@modamixtrends.com</p>
            <p>
              <div className="text-xl font-semibold mb-4 mt-4">الهاتف:</div>
              <div className="flex flex-wrap gap-4 justify-center">
                <div className="flex mb-2 pl-4 gap-4 items-center  bg-white text-gray-900 rounded-full p-1">
                  <div className="flex-center p-1 bg-blue-100 rounded-full">
                    <Phone />
                  </div>{" "}
                  <p className="text-lg font-bold">0694681426</p>
                </div>
                <div className="flex mb-2 pl-4 gap-4 items-center  bg-white text-gray-900 rounded-full p-1">
                  <div className="flex-center p-1 bg-blue-100 rounded-full">
                    <Phone />
                  </div>{" "}
                  <p className="text-lg font-bold">0620161140</p>
                </div>
                <div className="flex mb-2 pl-4 gap-4 items-center  bg-white text-gray-900 rounded-full p-1">
                  <div className="flex-center p-1 bg-blue-100 rounded-full">
                    <Phone />
                  </div>{" "}
                  <p className="text-lg font-bold">0651624878</p>
                </div>
              </div>
            </p>
            <p>العنوان: الدار البيضاء، حي المحمدي</p>
          </div>

          {/* روابط خدمة العملاء */}
          <div>
            <h4 className="text-xl font-semibold mb-4">خدمة العملاء</h4>
            <ul>
              <li>
                <Link to="/faqs" className="hover:underline">
                  الأسئلة الشائعة
                </Link>
              </li>
              <li>
                <Link to="/shipping-returns" className="hover:underline">
                  الشحن والإرجاع
                </Link>
              </li>
              <li>
                <Link to="/order-tracking" className="hover:underline">
                  تتبع الطلبات
                </Link>
              </li>
              <li>
                <Link to="/size-guide" className="hover:underline">
                  دليل المقاسات
                </Link>
              </li>
            </ul>
          </div>

          {/* معلومات الشركة */}
          <div>
            <h4 className="text-xl font-semibold mb-4">عنا</h4>
            <ul>
              <li>
                <Link to="/about" className="hover:underline">
                  من نحن
                </Link>
              </li>
              <li>
                <Link to="/careers" className="hover:underline">
                  فرص العمل
                </Link>
              </li>
            </ul>
          </div>

          {/* البقاء محدثًا */}
          <div>
            <h4 className="text-xl font-semibold mb-4">ابق على اطلاع</h4>
            <p className="mb-4">
              اشترك في نشرتنا الإخبارية للحصول على آخر التحديثات والعروض.
            </p>
            <form>
              <input
                type="email"
                placeholder="بريدك الإلكتروني"
                className="p-2 w-full mb-2"
              />
              <button
                type="submit"
                className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 w-full"
              >
                اشتراك
              </button>
            </form>
          </div>
        </div>

        <div className="mt-8 border-t border-gray-700 pt-4 flex flex-col md:flex-row justify-between items-center">
          {/* روابط وسائل التواصل الاجتماعي */}
          <div className="flex gap-4 mb-4 md:mb-0">
            <Link
              to="https://www.tiktok.com/@modamix.trends"
              aria-label="Tiktok Moda Mix Trends"
            >
              <FaTiktok className="h-6 w-6" />
            </Link>
            <Link
              to="https://www.instagram.com/mo.damixtrends/"
              aria-label="Instagram Moda Mix Trends"
            >
              <FaInstagram className="h-6 w-6" />
            </Link>
          </div>

          {/* حقوق التأليف والنشر */}
          <div className=" mb-4 md:mb-0">
            <p>&copy; 2025 Moda Mix Trends. جميع الحقوق محفوظة.</p>
          </div>

          {/* روابط قانونية */}
          <div className="flex gap-4">
            <Link to="/privacy-policy" className="hover:underline">
              سياسة الخصوصية
            </Link>
            <Link to="/terms-of-service" className="hover:underline">
              شروط الخدمة
            </Link>
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
